import React, { useState, useEffect, useRef } from "react";
import { addDefultConten } from "./sceneSetupDefultContent";
import useLoadAsset from "./useLoadAsset";
import SceneComponent from "./SceneComponent"; // uses above component in same directory
import sceneSetup from "./sceneSetup";
import { Main, BabylonComp, Overlay, MeshButton } from "./style";
import * as BABYLON from "@babylonjs/core";
import { animateCamera } from "./animateCamera";
import { animateMesh } from "./animateMesh";
import { confSetup } from "./confSetup";
import { RetryStrategy } from "@babylonjs/core";
// import {addPointerDragBehavior} from './sceneSetupDefultContent'
import { addPointerDragBehavior } from "./meshClickBehavior";

const camStartPosition = confSetup.focusPoints.find((item) => item.name === "start_position");
// console.log(camStartPosition.alpha);

let cameraPos = {
	name: "focusPoint_default",
	pos: {
		alpha: camStartPosition.alpha,
		beta: camStartPosition.beta,
		radius: camStartPosition.radius,
	},
	update: false,
};
let renderAction = [];

const wpPath = "/wp-content/uploads/wpr2/build/";
const onRender = (scene) => {
	// CLG IF CAMER CHANGE

	for (const key in cameraPos.pos) {
		let newValue = BABYLON.Tools.ToDegrees(scene.cameras[0][key]);

		if (key === "radius") {
			// newValue = cameraPos.pos[key] = scene.cameras[0][key];
			newValue = scene.cameras[0][key];
		}

		if (cameraPos.pos[key] !== newValue) {
			cameraPos.pos[key] = newValue;
			cameraPos.update = true;
		}
	}

	if (cameraPos.update) {
		cameraPos.update = false;
		// console.log(cameraPos);
	}

	if (renderAction.length !== 0) {
		let item = BABYLON.MeshBuilder.CreateBox("box", { size: 0.1 }, scene);
		console.log(renderAction[0]);
		renderAction[0].meshes.map((mesh) => {
			console.log(mesh.name);
			let cloneItem = mesh.clone(mesh.name + "_clone");
			cloneItem.position = new BABYLON.Vector3(0, 0, 0);
			cloneItem.isVisible = true;

			let positions = cloneItem.getVerticesData(BABYLON.VertexBuffer.PositionKind);
			positions = positions.map((v) => 2 * v);
			cloneItem.updateVerticesData(BABYLON.VertexBuffer.PositionKind, positions);
			addPointerDragBehavior(cloneItem);
		});
		console.log(confSetup);
		renderAction.pop();
	}
};
let scene = {};

export default function BabylonComponent() {
	const [timeStamp, setTimeStamp] = useState(Date.now());

	const stateRef = useRef({
		prodMesh: [],
		prodOptions: [],
		prodMaterials: [],
		prodFocus: [],
		prodAnimation: [],
	});
	const [state, setState] = useState({
		prodMesh: [],
		prodOptions: [],
		prodMaterials: [],
		prodFocus: [],
		prodAnimation: [],
	});

	const mainSceneRef = useRef({});
	const [mainScene, setMainScene] = useState({});

	const loadAsset = useLoadAsset();

	let onSceneReady = async (scene) => {
		sceneSetup(scene);
		window.highLight = new BABYLON.HighlightLayer("highLightLayer1", scene);
		loadAsset(scene, wpPath, "window.babylon", setTimeStamp, setState, stateRef);
		// loadAsset(scene, wpPath, "axis.babylon", setTimeStamp, setState, stateRef);
		// loadAsset(scene, "axis.babylon", timeStampHandler, getMeshHandler);

		// mainSceneRef.current = scene;
		window.scene = scene;
		window.stateRef = stateRef;
		window.BABYLON = BABYLON;
		// console.log(scene.cameras[0]);
		setMainScene(scene);
		console.log(stateRef);
		console.log(confSetup);
	};

	// <Main>
	//   <Canvas3D>
	//   </Canvas3D>
	//  </Main>

	return (
		<BabylonComp>
			<SceneComponent
				antialias
				onSceneReady={onSceneReady}
				onRender={onRender}
				id='renderCanvas'></SceneComponent>
			<Overlay>
				<OverlayContent mainScene={mainScene} state={state} setState={setState} />
			</Overlay>
		</BabylonComp>
	);
}

function OverlayContent({ mainScene, state, setState }) {
	function focusPointAnimate(mesh) {
		cameraPos.name = mesh.name;

		let focusPointData = confSetup.focusPoints.find((item) => item.name === mesh.name);

		if (focusPointData === undefined) {
			focusPointData = confSetup.focusPoints.find((item) => item.name === "focusPoint_default");
		}

		animateCamera(mainScene, {
			radius: Number(focusPointData.radius),
			alpha: BABYLON.Tools.ToRadians(focusPointData.alpha),
			beta: BABYLON.Tools.ToRadians(focusPointData.beta),
			target: mesh._absolutePosition,
		});
	}

	return (
		<div>
			{/* <button
				onClick={() => {
					// console.log(BABYLON)
					// console.log(state.tools)
					renderAction.push({ action: "create_focus_point", meshes: state.tools });
				}}>
				add
			</button> */}
			<div>
				Vy:
				{state.prodFocus.map((mesh) => {
					return (
						<MeshButton
							key={mesh.name}
							onClick={() => {
								focusPointAnimate(mesh);
							}}>
							{getLang(mesh.name)}
						</MeshButton>
					);
				})}
			</div>
			<div>
				Kulör:
				{state.prodMaterials.map((material) => (
					<MeshButton
						key={material.name}
						onClick={() => {
							console.log(state.prodFocus.find((mesh) => mesh.name === "focusPoint_outside"));

							let mesh = state.prodFocus.find((mesh) => mesh.name === "focusPoint_outside");

							focusPointAnimate(mesh);

							state.prodMesh.map((mesh) => {
								if (mesh.name.includes("changeColor")) mesh.material = material;
							});
							state.prodOptions.map((mesh) => {
								if (mesh.name.includes("changeColor")) mesh.material = material;
							});
						}}>
						{getLang(material.name)}
					</MeshButton>
				))}
			</div>
			<div>
				Tillval:
				{confSetup.optionalMeshes.map((item) => {
					console.log(item);
					return (
						<MeshButton
							key={item.name}
							active={item.active}
							onClick={() => {
								item.meshNames.forEach((meshName) => {
									state.prodOptions.map((i) => {
										if (i.name === meshName) {
											console.log(i);
											i.isVisible = !i.isVisible;
										}
									});
								});
							}}>
							{getLang(item.name)}
						</MeshButton>
					);
				})}
				{/* -------
				{state.prodOptions.map((mesh) => (
					<MeshButton
						key={mesh.name}
						onClick={() => {
							mesh.isVisible = !mesh.isVisible;
						}}>
						{getLang(mesh.name)}
					</MeshButton>
				))} */}
			</div>
			Funktion:
			{state.prodAnimation[0] !== undefined && (
				<MeshButton
					onClick={() => {
						let mesh = state.prodFocus.find((mesh) => mesh.name === "focusPoint_outside");
						focusPointAnimate(mesh);
						animateMesh(
							mainScene,
							{
								rotation: state.prodAnimation[0].rotation,
							},
							state.prodAnimation[0],
							setState,
						);
					}}>
					Öppna/Stäng
				</MeshButton>
			)}
		</div>
	);
}

function FocusPoints(params) {
	return <div></div>;
}

function getLang(key) {
	const textString = confSetup.translate[key];
	return textString !== undefined ? textString : key;
}
