import * as BABYLON from "@babylonjs/core";

const SPEED_RATIO = 1;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

export function animateMesh(
	scene,
	{ rotation: { x: targetX, y: targetY, z: targetZ } },
	meshToMove, setState
) {
	// console.log(meshToMove.name);
	// console.log(BABYLON.Tools.ToDegrees(targetY));
	if (BABYLON.Tools.ToDegrees(targetY) === 0) {
		targetY = BABYLON.Tools.ToRadians(80);
	} else {
		targetY = BABYLON.Tools.ToRadians(0);
	}

	// targetY = targetY + 1;
	// console.log(targetX);
	meshToMove.animations = [
		createAnimation({
			property: "_rotation.x",
			from: meshToMove.rotation.x,
			to: targetX,
		}),
		createAnimation({
			property: "_rotation.y",
			from: meshToMove.rotation.y,
			to: targetY,
		}),
		createAnimation({
			property: "_rotation.z",
			from: meshToMove.rotation.z,
			to: targetZ,
		}),
	];

	scene.beginAnimation(meshToMove, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
	
	// setState(prev => { {prev, }})
	console.log(meshToMove.rotation)
}

const FRAMES_PER_SECOND = 60;

function createAnimation({ property, from, to }) {
	const ease = new BABYLON.CubicEase();
	ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

	const animation = BABYLON.Animation.CreateAnimation(
		property,
		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		FRAMES_PER_SECOND,
		ease,
	);
	animation.setKeys([
		{
			frame: 0,
			value: from,
		},
		{
			frame: 100,
			value: to,
		},
	]);

	return animation;
}

// function simplifyRadians(radians) {
// 	const simplifiedRadians = radians % (2 * Math.PI);

// 	return simplifiedRadians < 0
// 		? simplifiedRadians + BABYLON.Tools.ToRadians(360)
// 		: simplifiedRadians;
// }
