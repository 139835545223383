export const confSetup = {
	active: {
		optionalMeshes: "",
		focusPoints: "start_position",
		materials: "white",
	},
	optionalMeshes: [
		{
			name: "window_bars",
			meshNames: ["01_op_deko_1", "01_op_deko_2", "01_op_deko_2_changeColor"],
			active: true,
		},
	],
	focusPoints: [
		{
			name: "start_position",
			alpha: 68,
			beta: 80,
			radius: 6,
		},
		{
			name: "focusPoint_outside",
			alpha: 68,
			beta: 80,
			radius: 6,
		},
		{
			name: "focusPoint_inside",
			alpha: -65,
			beta: 80,
			radius: 6,
		},
		{
			name: "focusPoint_handle",
			alpha: -65,
			beta: 70,
			radius: 0.8,
		},
		{
			name: "focusPoint_edge",
			alpha: 75,
			beta: 90,
			radius: 0.4,
		},
		{
			name: "focusPoint_default",
			radius: 8,
			alpha: 90,
			beta: 90,
		},
	],
	translate: {
		focusPoint_edge: "Fasad karm",
		focusPoint_handle: "Handtag",
		focusPoint_outside: "Utsida",
		focusPoint_inside: "Insida",
		white: "Vit",
		green: "Grön",
		red: "Röd",
		blue: "Blå",
		"01_op_deko_2_changeColor": "Spröjs utsida",
		"01_op_deko_2": "Spröjs insida vit",
		window_bars: "Spröjs",
	},
};
