import styled from "styled-components";

export const Main = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	/* cursor: pointer; */
	overflow: hidden;
	height: 50vh;
	width: 50vw;
	align-self: center;
	background: linear-gradient(to bottom, #c2c2c2, #444857);

	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Likely future */
`;

export const BabylonComp = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	z-index: 1;
	/* height: 400px; */
	/* width: 400px; */
	/* height: 100vh; */
	/* width: 100vw; */
	touch-action: none;
	/* padding: 50px; */
	/* scroll-behavior: unset; */
	overflow: hidden;
`;

export const Overlay = styled.div`
	/* position: absolute; */
	display: flex;
	flex-direction: center;
	align-self: center;
	justify-content: center;
	justify-items: center;
	align-items: center;
	bottom: 10px;
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	z-index: 1;
	touch-action: none;
	background-color: grey;
`;

export const RenderCanvas = styled.canvas`
	outline-width: 0px !important;
	cursor: pointer;
	overflow: hidden;
	height: 500px;
	width: 500px;
	touch-action: none;
	background-color: rgba(70, 94, 98, 0.04);
`;

export const MeshButton = styled.button`
	
	background-color: white; /* Green */
	/* background-color: ${(props) => {
		if (props.active === true) {
			return '#A8A8A8';
		}
	}}; */
	border: none;
	color: white;
	padding: 6px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	/* background-color: white; */
	color: black;
	/* border: 2px solid #e7e7e7; */
	/* margin: 5px; */
	:hover {
		background-color: #e7e7e7;
	}
`;

export const LevelGroup = styled.div`
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Likely future */
	margin-left: 10px;
	font-size: 12px;
`;

export const LevelTitle = styled.div`
	background-color: ${(props) => {
		return props.selected && "#578485b3";
	}};
	/* margin-left: 5px;
  font-size: 12px; */
`;
