import * as BABYLON from "@babylonjs/core";
import { addPointerDragBehavior } from "./meshClickBehavior";

// import React, { useState, useEffect, useReducer, useContext } from "react";
// import { MyContext } from "../App";

const useLoadAsset = () => {
	// const { state, dispatch } = useContext(MyContext);

	const loadAsset = (scene, path, fileName, setTimeStamp, setState, stateRef) => {
		var assetsManager = new BABYLON.AssetsManager(scene);
		assetsManager.useDefaultLoadingScreen = false;
		var meshTask = assetsManager.addMeshTask("skull task", "", path, fileName);

		console.log(path)
		meshTask.onSuccess = function (task) {
			scene.meshes = uniqByKeepLast(scene.meshes, (it) => it.name);
			// console.log(task)
			// //PBR material
			// var sphereMat = new BABYLON.PBRMetallicRoughnessMaterial("sphereMat", scene);
			// sphereMat.alpha = 0.5;
			// sphereMat.baseColor = new BABYLON.Color3(1, 1, 1, 1);
			// sphereMat.metallic = 0.5;
			// sphereMat.roughness = 0.5;

			let prodMaterials = [];
			let prodOptions = [];
			let prodMesh = [];
			let prodFocus = [];
			let prodAnimation = [];
			let tools = [];

			scene.meshes.map((mesh) => {
				mesh.receiveShadows = true;
				mesh.isVisible = false;
				// shadowGenerator.addShadowCaster(mesh)
				if (mesh.name.includes("_#_")) prodMesh.push(mesh);
				if (mesh.name.includes("color_")) prodMaterials.push(mesh.material);
				if (mesh.name.includes("_op_")) prodOptions.push(mesh);
				if (mesh.name.includes("focusPoint_")) prodFocus.push(mesh);
				if (mesh.name.includes("animation_")) prodAnimation.push(mesh);
				if (mesh.name.includes("axiz_")) tools.push(mesh);
				if (mesh.name.includes("axiz_")){
					
	
				} 
				

				// return "hej";
				// mesh.material = sphereMat
				
				// if (mesh.name.includes("axiz_"))
				//  addPointerDragBehavior(mesh);
			});
			// timeStampHandler();
			// getMeshHandler(task.loadedMeshes)
			prodMesh.map((mesh) => (mesh.isVisible = true));

			stateRef.current = { prodMesh, prodOptions, prodMaterials, prodFocus, prodAnimation, tools };

			setState({ prodMesh, prodOptions, prodMaterials, prodFocus, prodAnimation, tools });

			setTimeStamp(Date.now());
			// return "hejhej2";
		};

		meshTask.onError = function (task) {
			console.log(task);
		};
		assetsManager.load();
	};

	return loadAsset;
};

export default useLoadAsset;

// console.log(uniqByKeepFirst(data, it => it.u))
// console.log(uniqByKeepLast(data, it => it.u))

// function uniqByKeepFirst(a, key) {
//   let seen = new Set();
//   return a.filter((item) => {
//     let k = key(item);
//     return seen.has(k) ? false : seen.add(k);
//   });
// }

function uniqByKeepLast(a, key) {
	return [...new Map(a.map((x) => [key(x), x])).values()];
}

// https://dev.to/vinodchauhan7/react-hooks-with-async-await-1n9g
// https://www.freecodecamp.org/news/how-to-create-react-hooks/
// https://ysfaran.github.io/blog/post/0002-use-state-with-promise/
