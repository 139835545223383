import * as BABYLON from "@babylonjs/core";
import {addDefultConten} from './sceneSetupDefultContent'

export default function sceneSetup(scene, shadowGenerator) {
	const canvas = scene.getEngine().getRenderingCanvas();

	const camera = new BABYLON.ArcRotateCamera(
		"camera1",
		BABYLON.Tools.ToRadians(-120), // Alpha (rotate Y axis)
		BABYLON.Tools.ToRadians(70), // Beta  (rotate X axis)
		3, // Radius (Zoom distanse)
		new BABYLON.Vector3(0, 0, 0), // TARGET setTarget(BABYLON.Vector3.Zero())
		scene,
	);

	// camera.setPosition(new BABYLON.Vector3(0, 5, -10));
	// camera.setPosition(new BABYLON.Vector3(-2.2, 1.7, -3.5));
	camera.attachControl(canvas, true);

	// CAMERA VIEW SETTINGS
	camera.fov = 0.6;
	camera.viewport = new BABYLON.Viewport(0.0, 0.0, 1, 1);
	// camera.useAutoRotationBehavior = true; // AUTO ROTATE START

	camera.minZ = 0.1;
	camera.maxZ = 10;

	// CAMERA NAVIGATION SETTINGS
	// camera.lowerAlphaLimit = -4.0; // ROTATE LIMIT
	// camera.upperAlphaLimit = 1.0; // ROTATE LIMIT
	camera.lowerBetaLimit = 0.2; // TILT UP LIMIT
	camera.upperBetaLimit = 2.5; // TILT DOWN LIMIT
	camera.lowerRadiusLimit = 0.3; // ZOOM IN LIMIT
	camera.upperRadiusLimit = 4; // ZOOM OUT LIMIT
	
	camera.wheelDeltaPercentage = 0.01; // ZOOM SPEED

	camera.panningSensibility = 2000; // PANNING SPEED 0 = off
	camera.panningDistanceLimit = 1;

	camera.useBouncingBehavior = false;

	// BACKGROUND COLOR AND ALPHA
	scene.clearColor = new BABYLON.Color4(0.1, 0.1, 0.1, 0.1);
	// scene.autoClear = false

	// RESOLUTION
	scene.getEngine().setHardwareScalingLevel(0.3);


 scene.imageProcessingConfiguration.contrast = 0.9;
 scene.imageProcessingConfiguration.exposure = 1.2;
	// LIGHTS AND REFLECTION
	addEnvironmentToScene(scene);
	addLight(scene);
  // shadowGenerator = makeShadow(scene);
	//   addLensFlairLight(scene);

	addDefultConten()
}

function addEnvironmentToScene(scene) {
	// Environment lighting maps to be cycled through
	var env256 = BABYLON.CubeTexture.CreateFromPrefilteredData(
		"/wp-content/uploads/wpr2/build/studio.env",
		// "/wp-content/uploads/wpr2/build/flowerRoad_clamped.env",
		// "https://raw.githubusercontent.com/PatrickRyanMS/BabylonJStextures/master/ENV/flowerRoad_clamped.env",
		scene,
	);
	env256.name = "flowerRoad_256";
	env256.gammaSpace = false;
	scene.environmentTexture = env256;
}


function addLight(scene) {
	const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(50, 50, 0), scene);
	light.intensity = 0.9;
	const light2 = new BABYLON.HemisphericLight("light2", new BABYLON.Vector3(-50, -50, 0), scene);
	light2.intensity = -0.3;


  // var lightA = new BABYLON.HemisphericLight("lightA", new BABYLON.Vector3(50, 50, 0), scene);
  // lightA.intensity = 0.5; // ORG
  // lightA.specular = new BABYLON.Color3(0.5, 0.0, 0.0);

  // var lightC = new BABYLON.HemisphericLight("lightC", new BABYLON.Vector3(-200, 100, 0), scene);
  // lightC.intensity = 0.41; // ORG
  // lightC.specular = new BABYLON.Color3(0.0, 0.0, 0.0);

  // var lightB = new BABYLON.DirectionalLight("lightB", new BABYLON.Vector3(10, 0, 0), scene);
  // // lightB.position = new BABYLON.Vector3(0, 5, 5);
  // lightB.intensity = 1; // ORG

}


function makeShadow(scene) {
  // light1
	var light5 = new BABYLON.SpotLight("spotLight5", new BABYLON.Vector3(-40, 40, -40), new BABYLON.Vector3(1, -1, 1), Math.PI / 5, 30, scene);
	light5.position = new BABYLON.Vector3(-40, 40, -40);

	// Shadows
	var shadowGenerator = new BABYLON.ShadowGenerator(2048, light5);
	shadowGenerator.bias = 0.001;
	shadowGenerator.normalBias = 0.02;
	light5.shadowMaxZ = 100;
	light5.shadowMinZ = 10;
	shadowGenerator.useContactHardeningShadow = true;
	shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
	shadowGenerator.setDarkness(0.5);
  return shadowGenerator
}

// function addLensFlairLight(scene) {
//   var light2 = new BABYLON.PointLight("Omni", new BABYLON.Vector3(20, 20, 100), scene);
//   light2.intensity = 0.4;

//   // Create the "God Rays" effect (volumetric light scattering)
//   var godrays = new BABYLON.VolumetricLightScatteringPostProcess(
//     "godrays",
//     1,
//     scene._activeCamera,
//     null,
//     500,
//     BABYLON.Texture.BILINEAR_SAMPLINGMODE,
//     scene.getEngine(),
//     false
//   );
//   // By default it uses a billboard to render the sun, just apply the desired texture
//   // position and scale
//   godrays.mesh.material.diffuseTexture = new BABYLON.Texture(
//     "sun.png",
//     scene,
//     true,
//     false,
//     BABYLON.Texture.BILINEAR_SAMPLINGMODE
//   );
//   godrays.mesh.material.diffuseTexture.hasAlpha = true;
//   // godrays.mesh.material.diffuseTexture.a = true;
//   godrays.mesh.position = new BABYLON.Vector3(-150, 100, 100);
//   godrays.mesh.scaling = new BABYLON.Vector3(200, 200, 200);
//   // godrays.mesh.hasAlpha = true;
//   // godrays.mesh.alpha = 0.4;

//   light2.position = godrays.mesh.position;
// }
