import * as BABYLON from "@babylonjs/core";
// import loadAsset from '../hooks/useLoadAsset'
import sceneSetup from "./sceneSetup";
// import sceneGui from "./sceneGui";
import {addPointerDragBehavior} from './meshClickBehavior'




export function addDefultConten(scene) {
  let box = BABYLON.MeshBuilder.CreateBox("box", { size: 1 }, scene);
  var ground = BABYLON.Mesh.CreateGround("ground1", 6, 6, 2, scene);
  // sceneGui(box, ground);
  addPointerDragBehavior(box);

  
  const highLightLayer1 = new BABYLON.HighlightLayer("highLightLayer1", scene);
  highLightLayer1.addMesh(box, BABYLON.Color3.Green());
  highLightLayer1.removeMesh(box);
}




// export function addPointerDragBehavior(item) {
//   // Create pointerDragBehavior in the desired mode
//   // var pointerDragBehavior = new BABYLON.PointerDragBehavior({});
//   var pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragPlaneNormal: new BABYLON.Vector3(0, 1, 0) });
//   // var pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: new BABYLON.Vector3(1, 1, 0) });

//   // Use drag plane in world space
//   pointerDragBehavior.useObjectOrientationForDragging = false;

//   // Listen to drag events
//   pointerDragBehavior.onDragStartObservable.add((event) => {
//     // console.log("dragStart");
//     // console.log(event);
//   });
//   pointerDragBehavior.onDragObservable.add((event) => {
//     // console.log("drag");
//     // console.log(event);
//   });
//   pointerDragBehavior.onDragEndObservable.add((event) => {
//     // console.log("dragEnd");
//     // console.log(event);
//   });
//   // If handling drag events manually is desired, set move attached to false
//   // pointerDragBehavior.moveAttached = false;
//   item.addBehavior(pointerDragBehavior);
// }
