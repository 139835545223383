// import './App.css';
import BabylonComponent from './babylon/BabylonComponent';

function App() {
  return (
    <div className="App">
        <BabylonComponent></BabylonComponent>
    </div>
  );
}

export default App;
