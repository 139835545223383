import * as BABYLON from "@babylonjs/core";

export function addPointerDragBehavior(item) {
  // Create pointerDragBehavior in the desired mode
  // var pointerDragBehavior = new BABYLON.PointerDragBehavior({});
  let pointerDragBehavior;

  // console.log(item.name);
  if (item.name.includes("axiz_x")) {
    pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: new BABYLON.Vector3(1, 0, 0) });
  } else if (item.name.includes("axiz_y")) {
    pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: new BABYLON.Vector3(0, 0, 1) });
  } else if (item.name.includes("axiz_z")) {
    pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: new BABYLON.Vector3(0, 1, 0) });
  } else {
    pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragPlaneNormal: new BABYLON.Vector3(0, 1, 0) });
  }
  // var pointerDragBehavior = new BABYLON.PointerDragBehavior({ dragAxis: new BABYLON.Vector3(1, 1, 0) });

  // Use drag plane in world space
  pointerDragBehavior.useObjectOrientationForDragging = false;

  // Listen to drag events
  pointerDragBehavior.onDragStartObservable.add((event) => {
    // console.log("dragStart");
    // console.log(event);
    // console.log(item)
  });
  pointerDragBehavior.onDragObservable.add((event) => {
    // console.log("drag");
    // console.log(event);
  });
  pointerDragBehavior.onDragEndObservable.add((event) => {
    // console.log("dragEnd");
    // console.log(event);
  });
  // If handling drag events manually is desired, set move attached to false
  // pointerDragBehavior.moveAttached = false;
  item.addBehavior(pointerDragBehavior);
}
