import * as BABYLON from "@babylonjs/core";

const SPEED_RATIO = 1;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

export function animateCamera(
	scene,
	{ radius, alpha, beta, target: { x: targetX, y: targetY, z: targetZ } },
) {
	// const camera = scene.activeCamera;
	const camera = scene.cameras[0];
	// const camera = scene.meshes[0];
	// console.log(scene.cameras[0]);
	camera.animations = [
		createAnimation({
			property: "radius",
			from: camera.radius,
			to: radius,
		}),
		createAnimation({
			property: "beta",
			// from: simplifyRadians(camera.beta),
			from: camera.beta,
			to: beta,
		}),
		createAnimation({
			property: "alpha",
			// from: simplifyRadians(camera.alpha),
			from: camera.alpha,
			to: alpha,
		}),
		createAnimation({
			property: "target.x",
			from: camera.target.x,
			to: targetX,
		}),
		createAnimation({
			property: "target.y",
			from: camera.target.y,
			to: targetY,
		}),
		createAnimation({
			property: "target.z",
			from: camera.target.z,
			to: targetZ,
		}),
	];

	scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
}

const FRAMES_PER_SECOND = 60;

function createAnimation({ property, from, to }) {
	const ease = new BABYLON.CubicEase();
	ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

	const animation = BABYLON.Animation.CreateAnimation(
		property,
		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		FRAMES_PER_SECOND,
		ease,
	);
	animation.setKeys([
		{
			frame: 0,
			value: from,
		},
		{
			frame: 100,
			value: to,
		},
	]);

	return animation;
}

// function simplifyRadians(radians) {
// 	const simplifiedRadians = radians % (2 * Math.PI);


// 	return simplifiedRadians < 0
// 		? simplifiedRadians + BABYLON.Tools.ToRadians(360)
// 		: simplifiedRadians;
// }
